/* You can add global styles to this file, and also import other style files */

//@import "./theme/old-styles.scss";

html{
  overflow: hidden !important;
}

body {
  font-family: "Noto Sans";
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}


@import 'src/theme/epione-theme.scss';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/icon?family=Noto+Sans');




